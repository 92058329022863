.proserve-content {
    row-gap: 20px;
    padding: 10px;
}

@media screen and (min-width: 992px) {
    .proserve-content {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 50px;
        padding: 10px;
    }
}

.column-box {
    padding: 10px;
}

.row-box {
    margin: 5px;
    padding: 10px;
}

.program-content {
    align-items: center;
    row-gap: 50px;
    padding: 10px;
}

@media screen and (min-width: 300px) {
    .program-content {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        padding: 10px;
    }
}

.programs {
    display: grid;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.program-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f0f0f0;
    transition: background-color 0.3s ease;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.program-circle:hover {
    background-color: #e0e0e0;
}

.program-circle h3 {
    font-size: 12px;
    margin-bottom: 5px;

}

.program-circle p {
    font-size: 13px;
}

.right-col {
    text-align: start;
}

.brand-name {
    font-size: 40px;
    font-weight: 500;
    padding-bottom: 20px;
}

.imageside {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
