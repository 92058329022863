.corevalues .section-t{
    margin-bottom: 30px;
}
.corevalues .item-icon{
    margin-right: 28px;
    font-size: 46px;
}
.corevalues .item-title{
    margin-top: 0;
}
.corevalues .item-list{
    row-gap: 30px;
}
.corevalues-content{
    row-gap: 60px;
}

.item-text {
    h3 {
        font-size: large;
    }
    p {
        font-size: small;
    }
}



@media screen and (min-width: 992px){
    .corevalues-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 60px;
    }
}