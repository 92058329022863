.header{
    min-height: 100vh;
    background: linear-gradient(rgba(21, 21, 21, 0.7), rgba(26, 25, 25, 0.7)), url("../../assets/images/header_main_img.jpg") center/cover no-repeat;
    /* background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url("../../assets/images/header_main_img.jpg") center/cover no-repeat; */
} 
.header-content .text-lead{
    margin: 3.2rem 0 4.9rem 0;
}
.header-title{
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    background:  var(--clr-white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
    
}
.header-btn{
    align-self: center!important;
    margin-top: 40px;
    border-radius: 20px;
    color: rgb(255, 255, 255);
}
.header-btn span{
    font-size: 15px;
    
}



@media screen and (min-width: 600px){
    .header-title{
        font-size: 50px;
    }
    .header-content *{
        max-width: 920px;
    }
}