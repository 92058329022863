.footer{
    display: flex;
    padding: 30px 0;
    height: 300px;
}

.footer-content{
    grid-template-columns: repeat(4, 1fr);
    row-gap: 0; 
    text-align: left;
    justify-content: space-between;
}
.footer-content p{
    text-align: left;
}

.footer-content .text{
    font-size: 12px !important;
}

.footer-content h1{
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
}

.company{
    h3 {
        font-size: 14px !important;
        margin-bottom: 15px !important; 
        text-align: start !important;
    }
    p {
        margin-bottom: 10px;
    }
    
}

.footer-content span{
    text-align: right;
}

@media screen and (min-width: 768px){
    .footer-content{
        grid-template-columns: repeat(4, 1fr);
        row-gap: 0;
        column-gap: 50px;
        column-width: 20px;
        text-align: left;
        justify-content: space-between;
    }

}