
.fintech-content {
    row-gap: 20px;
    /* border: 1px solid;  */
    padding: 10px;

}

@media screen and (min-width: 992px){
    .fintech-content {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        padding: 10px;
        /* border: 1px solid;  */
    }
}

.fintech-content {
    align-items: center;
    row-gap: 50px;
    padding: 10px;
    /* border: 1px solid;  */
  
}

@media screen and (min-width: 300px){
    .fintech-content {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        padding: 10px;
        /* border: 1px solid;  */
    }
}

.fintech {
    img{
        width: 50px;
        margin-bottom: 7px;
        
    }

    h3 {
        font-size: 15px;
        margin-bottom: 5px ;
    }

    p {
        font-size: 13px;
    }
}

.text-p{
    font-size: 17px;
}


.program-circle h3 {
    font-size: 12px;
    margin-bottom: 5px;

}