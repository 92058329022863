@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* variables */
:root{
  --fnt-base: 'Poppins','Roboto';
  --clr-robin-blue: #5764de;
  --clr-crazy-blue: #55b3d5;
  --clr-black: #000;
  --clr-brand: rgb(255, 196, 0);
  --clr-md-black: #111;
  --clr-dark: #191919;
  --clr-white: #fff;
  --clr-whitesmoke: #f5f5f5;
  --clr-brandt: #6F7CB2;
  /* grey shades */
  --clr-grey: #8e8e8e;
  --spacing: 0.1rem;
  --gradient: linear-gradient(90deg, rgba(16, 201, 195, 1) 0%, rgba(85, 179, 213, 1) 100%);
  --transition: all 300ms linear;
  --clr-dgray:#464646;
  --clr-whitea: #EAEAEA;
}

/* global styles */
*,
*::after,
*::before{
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  scroll-behavior: smooth;
}
html{
  font-size: 10px;
}
body{
  font-size: 1.9rem;
  line-height: 1.6;
  font-family: var(--fnt-base);
  color: var(--clr-black);
}
ul{
  list-style-type: none;
}
a{
  text-decoration: none;
  color: unset;
}
h1, h2, h3, h4, h5, h6{
  letter-spacing: var(--spacing);
  text-transform: capitalize;
}
img{
  width: 100%;
  display: block;
}
.img-cover{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
button{
  cursor: pointer;
  outline: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 1.8rem;
}
input, textarea, select{
  outline: 0;
  border: none;
  resize: none;
}

/* custom classes */
.container{
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 2rem;
}


/* flexbox and grid */
.flex{
  display: flex;
  align-items: center;
}
.flex-column{
  flex-direction: column;
}
.flex-center{
  justify-content: center;
}
.flex-between{
  justify-content: space-between;
}
.flex-start{
  justify-content: flex-start;
}
.flex-end{
  justify-content: flex-end;
}

.grid{
  display: grid;
}

/* height and width */
.h-100{height: 100%;}
.w-100{width: 100%;}

/* text and paras */
.text{
  line-height: 1.55;
  font-weight: 300;
  font-size: 19px;
  color: var(--clr-grey);
  font-weight: 400;
}

.text-lead{
  font-size: 2.5rem;
  line-height: 1.4;
  font-weight: 500;
  color: var(--clr-grey);
}

/* text align */
.text-center{text-align: center;}
.text-start{text-align: left;}
.text-end{text-align: right;}

/* text transform */
.text-uppercase{text-transform: uppercase;}
.text-capitalize{text-transform: capitalize;}

/* text colors */
.text-grey{color: var(--clr-grey);}
.text-white{color: var(--clr-white);}
.text-robin-blue{color: var(--clr-robin-blue);}
.text-black{color: var(--clr-black);}
.text-crazy-blue{color: var(--clr-crazy-blue);}
.text-brandt{color: var(--clr-brandt);}
.text-brand{color: var(--clr-brand);}

/* font weights */
.fw-2{font-weight: 200;}
.fw-3{font-weight: 300;}
.fw-4{font-weight: 400;}
.fw-5{font-weight: 500;}
.fw-6{font-weight: 600;}
.fw-7{font-weight: 700;}
.fw-8{font-weight: 800;}
.fw-9{font-weight: 900;}

/* common font sizes */
.fs-12{font-size: 12px;}
.fs-13{font-size: 13px;}
.fs-14{font-size: 14px;}
.fs-15{font-size: 15px;}
.fs-16{font-size: 16px;}
.fs-17{font-size: 17px;}
.fs-18{font-size: 18px;}
.fs-19{font-size: 19px;}
.fs-20{font-size: 20px;}
.fs-21{font-size: 21px;}
.fs-22{font-size: 22px;}
.fs-23{font-size: 23px;}
.fs-24{font-size: 24px;}
.fs-25{font-size: 25px;}
.fs-26{font-size: 26px;}

/* letter spacing */
.ls-1{letter-spacing: 1px;}
.ls-2{letter-spacing: 2px;}

/* backgrounds */
.bg-transparent{background-color: transparent;}
.bg-white{background-color: var(--clr-white);}
.bg-black{background-color: var(--clr-black);}
.bg-robin-blue{background-color: var(--clr-robin-blue);}
.bg-dark{background-color: var(--clr-dark);}
.bg-md-black{background-color: var(--clr-md-black);}
.bg-white-a{background-color: var(--clr-whitea);}

/* buttons */
.btn{
  display: inline-block;
  min-height: 48px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-white);
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.95;
}
.btn-blue{
  background-color: var(--clr-robin-blue);
}
.btn-gray{
  background-color: var(--clr-grey);
}

/* margin */
.mx-auto{
  margin-right: auto;
  margin-left: auto;
}

/* animation and transition stopper */
.resize-animation-stopper *{
  animation: none!important;
  transition: none!important;
}

/* sections */
.section-h{
  font-family: var(--fnt-base);
  font-size: 4.4rem;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 8px;
}
.section-p{
  padding: 10rem 0;
}
.section-p-top{
  padding-top: 5rem;
}
.section-t{
  font-size: 44px;
  color: var(--clr-white);
  padding: 20px 0;
  position: relative;
  line-height: 1.2;
}
.section-t p{
  margin-top: 26px;
}
.section-t::before{
  content: "";
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  height: 5px;
  width: 60px;
  background: rgb(87, 100, 222);
  background: linear-gradient(45deg, rgba(87, 100, 222, 1) 0%, rgba(85, 179, 213, 1) 100%);
}

/* item */
.item-title{
  margin: 18px 0;
}

@media screen and (min-width: 768px){
  .section-t{
    text-align: left;
  }
  .section-t::before{
    left: 0;
    transform: translateX(0);
  }
}

/* hover effect */
.translate-effect:hover{
  transform: translateY(-20px);
}

.parah{
  text-align: justify;
}
.navbar{
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    background-color: var(--clr-white);
    z-index: 999;
}
.navbar-brand{
    color: var(--clr-black);
}

.hamburger-menu{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 26px;
    width: 34px;
    cursor: pointer;
    z-index: 10;
}
.bar-top, .bar-middle, .bar-bottom{
    height: 3px;
    background-color: var(--clr-white);
    transform-origin: left;
    transition: var(--transition);
}
.hamburger-menu-change .bar-top{
    transform: rotate(42deg);
}
.hamburger-menu-change .bar-middle{
    transform: translateX(1rem);
    opacity: 0;
}
.hamburger-menu-change .bar-bottom{
    transform: rotate(-42deg);
}
.navbar-collapse{
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--clr-dark);
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateX(100%);
    transition: var(--transition);
}
.show-navbar-collapse{
    transform: translateX(0);
}
.navbar-collapse-content{
    padding: 40px;
}
.navbar-nav{
    margin-bottom: 30px;
   
}
.navbar-nav li{
    margin: 20px 0;
    font-size:  1.5rem;
    transition: var(--transition);
    font-weight: 500;
}
.navbar-nav li:hover{
    color: var(--clr-black);
}
.navbar-social li{
    margin-right: 12px;
    font-size: 2.4rem;
}
.navbar-btns{
    margin-top: 2.4rem;
}
.navbar-btns button{
    border: 2px solid var(--clr-robin-blue);
    transition: var(--transition);
}
.navbar-btns button span{
    margin-left: 8px;
}
.navbar-btns button:hover{
    background-color: var(--clr-robin-blue);
}

@media screen and (min-width: 578px){
    .navbar-collapse{
        width: 320px;
    }
}

@media screen and (min-width: 992px){
    .navbar-collapse{
        transform: translateX(0);
        position: relative;
        background-color: transparent;
        box-shadow: none;
        top: 0;
        height: auto;
        width: 100%;
    }
    .navbar-collapse-content{
        padding: 0;
        display: flex;
        align-items: center;
    }
    .hamburger-menu{
        display: none;
    }
    .navbar-nav{
        display: flex!important;
        margin-bottom: 0;
    }
    .navbar-btns{
        margin-top: 0;
        margin-left: 40px;
    }
    .navbar-nav li{
        margin: 0;
        font-size: 13px;
        font-weight: 400;
        margin-left: 15px;
        margin-right: 5px;
    }
    .navbar-nav li:hover{
        color: var(--clr-crazy-blue);
    }
    .navbar-social{
        margin-left: 60px;
    }
    .navbar-social li{
        display: flex;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
    }
    .navbar-social li:hover{
        color: var(--clr-crazy-blue);
    }
}
.header{
    min-height: 100vh;
    background: linear-gradient(rgba(21, 21, 21, 0.7), rgba(26, 25, 25, 0.7)), url(/static/media/header_main_img.f13d7bdd.jpg) center/cover no-repeat;
    /* background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url("../../assets/images/header_main_img.jpg") center/cover no-repeat; */
} 
.header-content .text-lead{
    margin: 3.2rem 0 4.9rem 0;
}
.header-title{
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    background:  var(--clr-white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
    
}
.header-btn{
    align-self: center!important;
    margin-top: 40px;
    border-radius: 20px;
    color: rgb(255, 255, 255);
}
.header-btn span{
    font-size: 15px;
    
}



@media screen and (min-width: 600px){
    .header-title{
        font-size: 50px;
    }
    .header-content *{
        max-width: 920px;
    }
}
.services {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .services .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .services .services-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .services .item-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 20px;
  }
  
  .services .item {
    position: relative;
    width: 300px;  /* Adjust the width of the container */
    height: 400px; /* Adjust the height of the container */
    overflow: hidden; /* Hide any overflow to ensure the image fits within the box */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .services .item .title {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    color: var(--clr-white);
    width: 100%;
    text-align: center;
    padding: 10px 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .services .item:hover .title {
    opacity: 1;
  }
  
  .imagea,
  .image2,
  .image3 {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
  }
  
  .imagea {
    background-image: url(/static/media/m1.ce16327b.jpg);
  }
  
  .image2 {
    background-image: url(/static/media/m2.cf2526bc.jpg);
  }
  
  .image3 {
    background-image: url(/static/media/m3.46ee8229.jpg);
  }
  
  .text-service {
    line-height: 1.55;
    font-weight: 300;
    font-size: 30px;
    color: var(--clr-white);
    font-weight: 400;
    display: inline-block;
    margin-top: 15px;
  }
  
  @media screen and (min-width: 768px) {
    .services .item-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      column-gap: 30px;
      grid-row-gap: 30px;
      row-gap: 30px;
      margin-top: 10px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .services .item-list {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 40px;
      column-gap: 40px;
    }
  }
  
.text-service{
    font-weight: 750;
    color: black;
}

.para-service{
color:#6F7CB2;
font-size: 15px;
margin-top: 5px;
}

.header-btn{
    margin: 50px;    
    width: 50px;
    align-items: center;
}
.header-btn span{
    color: rgb(255, 255, 255);
    font-weight: 800;
}


.services3 .section-t{
    margin-bottom: 30px;
}

.services3-content{
    grid-row-gap: 60px;
    row-gap: 60px;
}


@media screen and (min-width: 992px){
    .services3-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        grid-column-gap: 60px;
        column-gap: 60px;
    }
}

.section-s3{
    font-size: 30px;
    color: var(--clr-white);
    padding: 20px 0;
    position: relative;
    line-height: 1.2;
  }

.textser{
    margin-top: 10px;
}
.footer{
    display: flex;
    padding: 30px 0;
    height: 300px;
}

.footer-content{
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 0;
    row-gap: 0; 
    text-align: left;
    justify-content: space-between;
}
.footer-content p{
    text-align: left;
}

.footer-content .text{
    font-size: 12px !important;
}

.footer-content h1{
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
}

.company{
    h3 {
        font-size: 14px !important;
        margin-bottom: 15px !important; 
        text-align: start !important;
    }
    p {
        margin-bottom: 10px;
    }
    
}

.footer-content span{
    text-align: right;
}

@media screen and (min-width: 768px){
    .footer-content{
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 0;
        row-gap: 0;
        grid-column-gap: 50px;
        column-gap: 50px;
        column-width: 20px;
        text-align: left;
        justify-content: space-between;
    }

}
.corevalues .section-t{
    margin-bottom: 30px;
}
.corevalues .item-icon{
    margin-right: 28px;
    font-size: 46px;
}
.corevalues .item-title{
    margin-top: 0;
}
.corevalues .item-list{
    grid-row-gap: 30px;
    row-gap: 30px;
}
.corevalues-content{
    grid-row-gap: 60px;
    row-gap: 60px;
}

.item-text {
    h3 {
        font-size: large;
    }
    p {
        font-size: small;
    }
}



@media screen and (min-width: 992px){
    .corevalues-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        grid-column-gap: 60px;
        column-gap: 60px;
    }
}
.aboutheader{
    min-height: 100vh;
    /* background: linear-gradient(rgba(21, 21, 21, 0.7), rgba(26, 25, 25, 0.7)); */
    /* background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url("../../assets/images/header_main_img.jpg") center/cover no-repeat; */
} 

.about-content{
    grid-row-gap: 10px;
    row-gap: 10px;
}

@media screen and (min-width: 768px){
    .about-content{
        text-align: left;
    }
}

@media screen and (min-width: 992px){
    .about-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        grid-column-gap: 50px;
        column-gap: 50px;
    }
}
.proserve-content {
    grid-row-gap: 20px;
    row-gap: 20px;
    padding: 10px;
}

@media screen and (min-width: 992px) {
    .proserve-content {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 50px;
        column-gap: 50px;
        padding: 10px;
    }
}

.column-box {
    padding: 10px;
}

.row-box {
    margin: 5px;
    padding: 10px;
}

.program-content {
    align-items: center;
    grid-row-gap: 50px;
    row-gap: 50px;
    padding: 10px;
}

@media screen and (min-width: 300px) {
    .program-content {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 50px;
        column-gap: 50px;
        padding: 10px;
    }
}

.programs {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.program-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f0f0f0;
    transition: background-color 0.3s ease;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.program-circle:hover {
    background-color: #e0e0e0;
}

.program-circle h3 {
    font-size: 12px;
    margin-bottom: 5px;

}

.program-circle p {
    font-size: 13px;
}

.right-col {
    text-align: start;
}

.brand-name {
    font-size: 40px;
    font-weight: 500;
    padding-bottom: 20px;
}

.imageside {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.financialcafe{
    background-color: #EAEAEA;
}
.financialcafe-content {
    grid-row-gap: 20px;
    row-gap: 20px;
    /* border: 1px solid;  */
    padding: 10px;

}

@media screen and (min-width: 992px){
    .financialcafe-content {
        grid-template-columns: repeat(2, 1fr); 
        grid-column-gap: 50px; 
        column-gap: 50px;
        padding: 10px;
        /* border: 1px solid;  */
    }
}

.finan-content {
    align-items: center;
    grid-row-gap: 50px;
    row-gap: 50px;
    padding: 10px;
    /* border: 1px solid;  */
  
}

@media screen and (min-width: 300px){
    .finan-content {
        grid-template-columns: repeat(3, 1fr);
        /* align-items: center; */
        grid-column-gap: 50px;
        column-gap: 50px;
        padding: 10px;
        /* border: 1px solid;  */
    }
}

.finanservices {
    img{
        width: 50px;
        margin-bottom: 7px;
        
    }

    h3 {
        font-size: 15px;
        margin-bottom: 5px ;
    }

    p {
        font-size: 13px;
    }
}

.row-box { 
    margin: 5px;
    padding: 10px;
    /* border: 1px solid; */
}

.image-left{
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fintech-content {
    grid-row-gap: 20px;
    row-gap: 20px;
    /* border: 1px solid;  */
    padding: 10px;

}

@media screen and (min-width: 992px){
    .fintech-content {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 50px;
        column-gap: 50px;
        padding: 10px;
        /* border: 1px solid;  */
    }
}

.fintech-content {
    align-items: center;
    grid-row-gap: 50px;
    row-gap: 50px;
    padding: 10px;
    /* border: 1px solid;  */
  
}

@media screen and (min-width: 300px){
    .fintech-content {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 50px;
        column-gap: 50px;
        padding: 10px;
        /* border: 1px solid;  */
    }
}

.fintech {
    img{
        width: 50px;
        margin-bottom: 7px;
        
    }

    h3 {
        font-size: 15px;
        margin-bottom: 5px ;
    }

    p {
        font-size: 13px;
    }
}

.text-p{
    font-size: 17px;
}


.program-circle h3 {
    font-size: 12px;
    margin-bottom: 5px;

}



