.services {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .services .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .services .services-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .services .item-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }
  
  .services .item {
    position: relative;
    width: 300px;  /* Adjust the width of the container */
    height: 400px; /* Adjust the height of the container */
    overflow: hidden; /* Hide any overflow to ensure the image fits within the box */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .services .item .title {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    color: var(--clr-white);
    width: 100%;
    text-align: center;
    padding: 10px 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .services .item:hover .title {
    opacity: 1;
  }
  
  .imagea,
  .image2,
  .image3 {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
  }
  
  .imagea {
    background-image: url("../../assets/images/m1.jpg");
  }
  
  .image2 {
    background-image: url("../../assets/images/m2.jpg");
  }
  
  .image3 {
    background-image: url("../../assets/images/m3.jpg");
  }
  
  .text-service {
    line-height: 1.55;
    font-weight: 300;
    font-size: 30px;
    color: var(--clr-white);
    font-weight: 400;
    display: inline-block;
    margin-top: 15px;
  }
  
  @media screen and (min-width: 768px) {
    .services .item-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
      row-gap: 30px;
      margin-top: 10px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .services .item-list {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 40px;
    }
  }
  