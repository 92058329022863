.aboutheader{
    min-height: 100vh;
    /* background: linear-gradient(rgba(21, 21, 21, 0.7), rgba(26, 25, 25, 0.7)); */
    /* background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url("../../assets/images/header_main_img.jpg") center/cover no-repeat; */
} 

.about-content{
    row-gap: 10px;
}

@media screen and (min-width: 768px){
    .about-content{
        text-align: left;
    }
}

@media screen and (min-width: 992px){
    .about-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 50px;
    }
}