.text-service{
    font-weight: 750;
    color: black;
}

.para-service{
color:#6F7CB2;
font-size: 15px;
margin-top: 5px;
}

.header-btn{
    margin: 50px;    
    width: 50px;
    align-items: center;
}
.header-btn span{
    color: rgb(255, 255, 255);
    font-weight: 800;
}

