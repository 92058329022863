.services3 .section-t{
    margin-bottom: 30px;
}

.services3-content{
    row-gap: 60px;
}


@media screen and (min-width: 992px){
    .services3-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 60px;
    }
}

.section-s3{
    font-size: 30px;
    color: var(--clr-white);
    padding: 20px 0;
    position: relative;
    line-height: 1.2;
  }

.textser{
    margin-top: 10px;
}