.financialcafe{
    background-color: #EAEAEA;
}
.financialcafe-content {
    row-gap: 20px;
    /* border: 1px solid;  */
    padding: 10px;

}

@media screen and (min-width: 992px){
    .financialcafe-content {
        grid-template-columns: repeat(2, 1fr); 
        column-gap: 50px;
        padding: 10px;
        /* border: 1px solid;  */
    }
}

.finan-content {
    align-items: center;
    row-gap: 50px;
    padding: 10px;
    /* border: 1px solid;  */
  
}

@media screen and (min-width: 300px){
    .finan-content {
        grid-template-columns: repeat(3, 1fr);
        /* align-items: center; */
        column-gap: 50px;
        padding: 10px;
        /* border: 1px solid;  */
    }
}

.finanservices {
    img{
        width: 50px;
        margin-bottom: 7px;
        
    }

    h3 {
        font-size: 15px;
        margin-bottom: 5px ;
    }

    p {
        font-size: 13px;
    }
}

.row-box { 
    margin: 5px;
    padding: 10px;
    /* border: 1px solid; */
}

.image-left{
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}